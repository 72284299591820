import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getLanguage } from '@/utils/i18n-utils'

Vue.use(VueI18n)

export const i18n = new VueI18n({
    locale: getLanguage(),
    fallbackLocale: 'en-US',
    silentFallbackWarn: true,
    silentTranslationWarn: true,
})
