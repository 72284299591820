<template>
    <div>
        <adam-header-bar
            :email-address="userProfile.email"
            :show-notifications.prop="false"
            :user-name="userProfile.name"
            :class="[
                { 'adam-header-bar--bg-dev': isDevEnvironment },
                { 'adam-header-bar--bg-uat': isUatEnvironment },
            ]"
            @apps-click="clickDrawer"
        >
            <span slot="headerContent">
                <img
                    class="logo"
                    src="../../public/logo/logo-coode-white.svg"
                    alt="Logo"
                >
                <span class="adam-header__separator" />
                {{ appTitle }}
            </span>
            <div slot="right">
                <pui-form-select
                    :options="languageOptions"
                    :value="$i18n.locale"
                    :search-input-placeholder="$t('selectLanguage')"
                    label=""
                    class="language-selector"
                    @change="changeLanguage"
                />
            </div>
            <span slot="profileDetails">
                <adam-header-user-profile :user="userProfile" />
            </span>
        </adam-header-bar>
        <app-drawer
            ref="appDrawer"
            :data.prop="applications"
            :labels.prop="getAppDrawerLabels"
            :on-click.prop="handleAppClick"
        >
            <span
                slot="appLogo"
                class="app-logo"
            >
                <img src="~adam.ui-core/assets/brand/logo-coode-rgb.svg">
            </span>
        </app-drawer>
    </div>
</template>

<script lang="ts">

import {Component, Vue} from 'vue-property-decorator'
import {UseCase} from '@/models/UseCase'
import {Application} from '@/models/application'
import AdamHeaderUserProfile from '@/components/AdamHeaderUserProfile.vue'
import { mapGetters } from 'vuex'
import { UserProfile } from '@/models/UserProfile'
import { Environments } from '@coode/fe-sdk'
import { setLanguage } from '@/utils/i18n-utils'

@Component({
    name: 'adam-header',
    components: {AdamHeaderUserProfile},
    computed: mapGetters({
        userProfile: 'user',
        useCases: 'useCases'
    }),
})
export default class HeaderWrapper extends Vue {
    private userProfile!: UserProfile;
    private useCases!: Application[];
    private appTitle = process.env.VUE_APP_NAME;

    private get isDevEnvironment(): boolean {
        return ['dev', 'development'].includes(process.env.VUE_APP_ENVIRONMENT as Environments)
    }

    private get isUatEnvironment(): boolean {
        return process.env.VUE_APP_ENVIRONMENT == 'uat'
    }
    
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    get getAppDrawerLabels(): any {
        return {applications: this.$i18n.t('appsWithAccess'), miscellaneous: this.$i18n.t('appsWithoutAccess')}
    }

    private get applications(): UseCase[] {
        return this.useCases.map((app: Application): UseCase => ({
            id: app.useCaseId,
            type: app.hasAccess ? 'APPLICATION' : '',
            label: app.name,
            iconId: this.getLogo(app.logo),
            url: app.url ? app.url : undefined,
        }))?.sort((a: UseCase, b: UseCase) => {
            const nameA = a.label.toLowerCase()
            const nameB = b.label.toLowerCase()
            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) {
                return 1
            }
            return 0
        })
    }

    private get languageOptions(): { label: string; value: string }[] {
        return this.$i18n.availableLocales.map((lang) => {
            return {
                label: this.$t(`languages.${lang}`) as string,
                value: lang,
            }
        })
    }

    private changeLanguage(localeCode: string): void {
        if (this.$i18n.locale === localeCode) {
            return
        }

        setLanguage(localeCode)
    }

    private clickDrawer(): void {
        (this.$refs.appDrawer as any).open = true
    }

    private handleAppClick(app: Application): void {
        if (app.url) {
            window.open(app.url)
        }
    }

    private getLogo(img: string): string {
        try {
            require(`adam.ui-core/assets/icons/${img}.svg`)
            return img
        } catch (e) {
            return 'app-generic'
        }
    }
}
</script>

<style lang="less" scoped>
@import '../styles/variables.less';

.language-selector {
    width: 200px;
    margin-right: 1rem;
}

.app-logo {
    img {
        height: 100%;
        margin-right: 1rem;
    }
}

.logo {
    height: 80%;
    margin: 0 0.5rem;
}

.adam-header__separator {
    width: 0.2rem;
    height: 3rem;
    background: #0059A4;
    display: inline-block;
    vertical-align: middle;
    margin: 0 1rem;
}

::v-deep .adam-header-bar--bg-dev {
    .adam-header.adam-header-bar, adam-header-bar {
        background-color: @magenta-medium;
        .adam-header-user__icon.adam-header-bar path {
            fill: @magenta-medium;
        }
        .adam-header-user__icon--focused.adam-header-bar {
            background: @magenta-medium;
        }
        .adam-header-user__icon.adam-header-bar:hover,
        .adam-header-user__icon--focused.adam-header-bar {
            background: @magenta-darker;
        }
        .adam-header__logo.adam-header-bar:hover {
            --fill: @magenta-darker;
        }
    }
    .adam-header__separator {
        background: @magenta-darker;
    }
}

::v-deep .adam-header-bar--bg-uat {
    .adam-header.adam-header-bar, adam-header-bar {
        background-color: @violet-medium;
        .adam-header-user__icon.adam-header-bar path {
            fill:@violet-medium;
        }
        .adam-header-user__icon--focused.adam-header-bar {
            background: @violet-medium;
        }
        .adam-header-user__icon.adam-header-bar:hover,
        .adam-header-user__icon--focused.adam-header-bar {
            background: @violet-darker;
        }
        .adam-header__logo.adam-header-bar:hover {
            --fill: @violet-darker;
        }
    }
    .adam-header__separator {
        background: @violet-darker;
    }
}
</style>
