import Vue, { PluginObject } from 'vue'
import App from './App.vue'
import {createRouter} from './router'
import store from './store'
import 'adam.ui-core/dist/umd'
import 'adam.ui-core/utils/scaling.css'
import './registerServiceWorker'
import {VNode} from 'vue/types/umd'
import 'vue-croppa/dist/vue-croppa.css'
/* @ts-expect-error: missing types for vue-croppa */
import Croppa from 'vue-croppa'
import PebbleUI from '@enerlytics/pebble-ui'
import '@/installCompositionApi.js'
import VueRouter from 'vue-router'
import VueApexCharts from 'vue-apexcharts'
import { sdk } from '@/utils/sdk'
import { i18n } from '@/utils/i18n'

Vue.config.productionTip = false
Vue.use(Croppa)
Vue.use(VueRouter)

/* @ts-expect-error: PebbleUI */
Vue.use(PebbleUI, {
    lang: i18n.locale,
    dateTimeLocale: 'en-GB'
})

Vue.use(VueApexCharts)

Vue.component('Apexchart', VueApexCharts)

const router = createRouter()

sdk?.auth.init().then(() => {
    new Vue({
        i18n,
        router,
        store,
        render: (h): VNode => h(App),
    }).$mount('#app')
})
