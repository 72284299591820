import Vue from 'vue'
import { getI18n } from './translation-manager'

export const EventBus = new Vue({
    data(): any {
        return {
            GLOBAL: {
                SHOW_SNACKBAR: 'global.show-snackbar',
            },
        }
    }
})

EventBus.$on('forbidden', () => {
    const i18n = getI18n()
    EventBus.$pui.toast({
        title: i18n.t('eventBus.forbidden.title'),
        copy: i18n.t('eventBus.forbidden.message'),
        autoDismiss: 5000
    })
})

EventBus.$on('copiedToClipboard', () => {
    const i18n = getI18n()
    EventBus.$pui.toast({
        title: i18n.t('eventBus.copiedToken.title'),
        copy: i18n.t('eventBus.copiedToken.message'),
        autoDismiss: 5000
    })
})

EventBus.$on('save', () => {
    const i18n = getI18n()
    EventBus.$pui.toast({
        title: i18n.t('eventBus.success'),
        copy: i18n.t('eventBus.save.message'),
        autoDismiss: 5000
    })
})

EventBus.$on('create', () => {
    const i18n = getI18n()
    EventBus.$pui.toast({
        title: i18n.t('eventBus.success'),
        copy: i18n.t('eventBus.create.message'),
        autoDismiss: 5000
    })
})

EventBus.$on('delete', () => {
    const i18n = getI18n()
    EventBus.$pui.toast({
        title: i18n.t('common.delete'),
        copy: i18n.t('deleteIncident.deleted'),
        autoDismiss: 5000
    })
})

EventBus.$on('error', () => {
    const i18n = getI18n()
    EventBus.$pui.toast({
        title: i18n.t('eventBus.error.title'),
        copy: i18n.t('eventBus.error.message'),
        autoDismiss: 5000
    })
})
