import {
    CreateIncidentResponse,
    IncidentPageResponse,
    IncidentResponse
} from '@/models/ResponseTypes'
import {AxiosRequestConfig} from 'axios'
import {IncidentRequest} from '@/models/RequestTypes'
import { sdk } from '@/utils/sdk'

export class IncidentService {
    endpoint = 'incidents'

    public async getIncidents(config?: AxiosRequestConfig): Promise<IncidentPageResponse> {
        return (await sdk?.backend.request.api.get<{result: IncidentPageResponse}>(
            `${this.endpoint}`,
            config
        )).data.result
    }

    public async getIncidentByIncidentId(incidentId: number, config?: AxiosRequestConfig): Promise<IncidentResponse> {
        return (await sdk?.backend.request.api.get<{result: IncidentResponse}>(
            `${this.endpoint}/${incidentId}`,
            config
        )).data.result
    }

    public async getIncidentByNotificationNumber(notificationNumber: string, config?: AxiosRequestConfig): Promise<IncidentResponse> {
        return (await sdk?.backend.request.api.get<{result: IncidentResponse}>(
            `${this.endpoint}/${notificationNumber}`,
            config
        )).data.result
    }

    public async putIncident(incident: IncidentRequest, config?: AxiosRequestConfig): Promise<IncidentPageResponse> {
        return (await sdk?.backend.request.api.put<{result: IncidentPageResponse}>(
            `${this.endpoint}/${incident.incidentId}`,
            incident,
            config
        )).data.result
    }

    public async postIncident(incident: IncidentRequest, config?: AxiosRequestConfig): Promise<CreateIncidentResponse> {
        return (await sdk?.backend.request.api.post<{result: CreateIncidentResponse}>(
            `${this.endpoint}`,
            incident,
            config
        )).data.result
    }

    public async deleteIncident(incidentId: number, siteId: number, config?: AxiosRequestConfig): Promise<any> {
        return (await sdk?.backend.request.api.delete<{result: any}>(
            `${this.endpoint}/${incidentId}/site/${siteId}`,
            config
        )).data.result
    }
}

export default new IncidentService()
