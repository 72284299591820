import '@/installCompositionApi.js'
import { computed } from '@vue/composition-api'
import useUser from './useUser'
import useIncident from './useIncident'
import useSiteSettings from './useSiteSettings'
import { Site } from '@/models/ResponseTypes'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function useSitePermissions() {
    const { selectedSite, availableSites, DEFAULT_SITE_ID } = useSiteSettings()
    const { currentUser } = useUser()
    const { selectedIncident } = useIncident()

    const checkIfSiteHasPermission = (site: Site, rightType: 'Write' | 'Read'): boolean=> {
        return site.plantIds.some(
            plantId => currentUser.value.assetPermissions?.some(
                p => p.assetSid === plantId && p.action === rightType
            ))
    }

    const hasAccessToViewAllMasterDataAssets = computed(() => currentUser.value.permissions?.includes('IsSuperAdmin'))
    const hasGlobalAdminRole = computed(() => currentUser.value.permissions?.includes('FracasGlobalAdmin'))

    const isGlobalUser = computed(() => hasAccessToViewAllMasterDataAssets.value && !hasGlobalAdminRole.value)

    const isSiteUser = computed(() => {
        if (isGlobalUser.value) return true
        if (!selectedSite.value) return false
        return checkIfSiteHasPermission(selectedSite.value, 'Read')

    })

    const isGlobalAdmin = computed(() => hasAccessToViewAllMasterDataAssets.value && hasGlobalAdminRole.value)

    const isSiteAdmin = computed(() => {
        if (isGlobalAdmin.value) return true
        if (!selectedSite.value) return false
        return checkIfSiteHasPermission(selectedSite.value, 'Write')
    })

    const preselectedUserSite = computed(() => {
        if (!availableSites.value) return

        const egtlSite = availableSites.value.find(site => site.siteId === DEFAULT_SITE_ID)
        const siteWhereUserIsAdmin = availableSites.value.find(site => checkIfSiteHasPermission(site, 'Write'))
        const siteWhereUserHasReadAccess = availableSites.value.find(site => checkIfSiteHasPermission(site, 'Read'))

        if (isGlobalAdmin.value)
            return egtlSite

        if (siteWhereUserIsAdmin)
            return siteWhereUserIsAdmin

        if (isGlobalUser.value)
            return egtlSite

        if (siteWhereUserHasReadAccess)
            return siteWhereUserHasReadAccess

        return egtlSite
    })

    const isInvestigatorForSelectedIncident = computed(() => {
        if (
            !selectedIncident.value ||
            !selectedIncident.value.investigator ||
            !selectedIncident.value.investigator.kid ||
            !currentUser.value.kid
        ) {
            return false
        }

        return selectedIncident.value.investigator.kid === currentUser.value.kid
    })

    return {
        isGlobalAdmin,
        isSiteUser,
        isSiteAdmin,
        preselectedUserSite,
        isInvestigatorForSelectedIncident,
    }
}
